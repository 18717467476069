import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCoffee } from '@fortawesome/free-solid-svg-icons';
import { faFacebookSquare, faDiscord} from '@fortawesome/free-brands-svg-icons';

function Footer() {
  return (
    <footer>
      <div class="icons">
        <a href="https://www.facebook.com/flowstategames/">
          <FontAwesomeIcon icon={faFacebookSquare} size="4x"/>
        </a>
        <a href="https://discord.gg/EJkKC36">
          <FontAwesomeIcon icon={faDiscord} size="4x"/>
        </a>
      </div>
      <div class="copyright">
        © Flowstate Games {new Date().getFullYear()}
      </div>
    </footer>
  );
}

export default Footer;