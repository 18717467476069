import React from 'react';
import apple_badge from './Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg';
import google_play_badge from  './google-play-badge.png';

function Badge() {
  return (
    <div className="Badge">
      <a href='https://play.google.com/store/apps/details?id=com.FlowstateGames.Juggl&hl=en&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'
      class="img-link">
        <img src={google_play_badge} className="Google-Play-badge" alt='Get it on Google Play' />
      </a>
      <a href='https://testflight.apple.com/join/SUV1vpbi'
      class="img-link">
        <img src={apple_badge} className="Apple-badge" alt="Download on the App Store" />
      </a>
    </div>
  );
}

export default Badge;