import React from 'react';
import './App.css';
import Video from './Video.js';
import Badge from './Badges.js';
import title from './Juggl-title-transparent.png';
import CustomForm from './MailChimp.js';
import TypedReactDemo from './TypingAnimation';

function App() {
 
  return (
    <div className="App">
      <header className="Title-header">
        <img src={title} className="Title" alt="Juggl" />
        <TypedReactDemo
          strings={[
            'A juggling simulation game.',
            'Unlock every pattern.',
            'Get the high score.',
          ]}
        />
        <Badge />
        <CustomForm />
      </header>
    </div>
  );
}

export default App;
